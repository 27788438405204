import React, { Component } from 'react'

export default class Project extends Component {
	render() {
		return (
			<div className='project__column column' >
				<div className="project__card card">
					<header className="project__card-header card-header">
						<h3 className="project__card-header-title card-header-title">
							{this.props.project.name}
						</h3>
					</header>
					<div className="project__card-image card-image">
						<figure className="image is-4by3">
							<img src={`/img/${this.props.project.name}.svg`} alt={this.props.project.name} />
						</figure>
					</div>
					<div className="project__card-content card-content">
						<div className="project__content content">
							{this.props.project.description}

	
						</div>
				
						<footer className="project__card-footer card-footer">
						<div className="row badges">
							{this.props.project.topics.map((topic, index) =>
								<span key={index} className="tag project__tag is-small">
									{topic}
								</span>
							)}</div>

							<div className="project__button-wrapper">

						
							<a href={this.props.project.html_url} className="project__card-footer-item card-footer-item button">
								<span className="project__icon icon">
									<i className="fas fa-code"></i>
								</span>
								<span>Code</span>
							</a>
							{this.props.project.homepage &&
								<a href={this.props.project.homepage} className="project__card-footer-item  card-footer-item button">
									<span className="project__icon icon">
										<i className="fas fa-eye"></i>
									</span>
									<span>live preview</span>
								</a>
							}
								</div>
						</footer>
					</div>
				</div>
			</div>
		)
	}
}
