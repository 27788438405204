import React from "react";
import Skills from "./components/Skills.jsx";
import Projects from "./components/Projects.jsx";
import Contact from "./components/Contact.jsx"
import Services from "./components/Services";
import { skills } from './data/data';
import { services } from './data/servicesItems';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Aboutme from './components/Aboutme'

const App = () => {
  return (
    <div>
      <Nav />
      <section className="section" id="aboutme" >
        <Aboutme />
      </section>
      <section className="section" id="projects">
        <div className="container">
          <Projects/>
        </div>
      </section>
      <section className="section" id="skills">
        <div className="container">
          <h2 className="title">Tools/coding skills</h2>
          <Skills skills={skills} />
        </div>
      </section>
      <section className="section" id="services">
        <div className="container">
          <h2 className="title">services</h2>
          <Services services={services} />
        </div>
      </section>
      <section className="section" id="contact">
        <div className="container">
				<h2 className="title">contact</h2>
          <Contact />
        </div>
      </section>
			<Footer id="footer"/>
    </div>
  );
}

export default App
