import React from 'react'
import { useSpring, animated } from 'react-spring'
import clamp from 'lodash-es/clamp'
import { useGesture } from 'react-with-gesture'

const About = () => {
	function Pull() {
		const [{ xy }, set] = useSpring(() => ({ xy: [0, 0] }))

		const bind = useGesture(({ down, delta, velocity }) => {
			velocity = clamp(velocity, 1, 8)
			set({ xy: down ? delta : [0, 0], config: { mass: velocity, tension: 500 * velocity, friction: 50 } })
		})
		return <animated.img draggable="false" src="/img/undraw-coding.png" alt="ugur profile photo" {...bind()} style={{ transform: xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`) }} />
	}
	return (
		<div className='aboutme-wrapper'>
			<div className="container aboutme">
				<div className="aboutme__content" >
					<h1 className="title aboutme__title">Imaginar Solutions - web/app development</h1>
					<div className="aboutme__profilephoto" >
						<Pull />
					</div>
					<p className="aboutme__text">
						Imaginar Solutions is focused on developing apps and websites that improves the users experience and customer satisfaction.
                    </p>
					<a href="#projects" role="button" className="aboutme__button" aria-label="Take a look at my projects">
						<span className="icon aboutme__icon icon">
							<i className="fas fa-arrow-circle-down fa-4x"></i>
						</span>
					</a>
				</div>
			</div>
		</div>
	)
}
export default About
