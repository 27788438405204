import React, { Component } from "react";
import Service from './Service';

export default class Services extends Component {

  render() {
    return (
      <div className="skills">
          <div className="skills__columns columns is-multiline">
            {this.props.services.map((service, index) =>
              <Service key={index} service={service} />
            )}
          </div>
        </div>
    );
  }
}

