import React, { Component } from 'react'

export default class Service extends Component {

	

  render() {
    return (
      <div className={`service column is-half ${this.props.service.nr === 3 ? 'service__center': ''}`} >
          <figure className="service__thumbnail">
            <img className="service__image" src={this.props.service.imgUrl} alt={this.props.service.text}/>
          </figure>
					<p className="service__text">{this.props.service.text}</p>
					<p className="service__price">{this.props.service.price}</p>
      </div>
    )
  }
}
