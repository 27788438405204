import React, { Component } from "react";
import Skill from './Skill';

export default class Skills extends Component {

  render() {
    return (
      <div className="skills">
          <div className="skills__columns columns is-multiline">
            {this.props.skills.map((skill, index) =>
              <Skill key={index} skill={skill} />
            )}
          </div>
        </div>
    );
  }
}

