export const services = [
  {
    imgUrl: '/img/undraw_wordpress_utxt.svg',
    text:"Wordpress website/webshop",
    price:"Typical price €500",
		nr:1

  },
  {
    imgUrl: '/img/undraw_progressive_app_m9ms.svg',
    text:"Custom website/web application",
    price:"Typical price €3000",
		nr:2
  },
  {
    imgUrl: '/img/undraw_mobile_development_8gyo.svg',
    text:"Custom native app",
    price:"Typical price €5000",
		nr:3
  },
]