import React, { Component } from 'react'
import Project from './Project';
export default class Projects extends Component {

	constructor(props) {
		super();

		this.state = {
			items: [],
			data: []
		}
	}

	async fetchData() {
		const response = await fetch(`https://api.github.com/users/ugur22/repos`);
		const responseData = await response.json();
		this.setState({
			data: responseData
		});
	}

	sortRepos = () => {
		return this.data.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)).slice(2, 8);
	}

	componentDidMount() {
		this.fetchData();
	}

	render() {
		this.data = this.state.data;

		return (
			<div className="projects">
				<h2 className="projects__title title">projects</h2>
				<div className='projects__columns'>
					{this.sortRepos().map((repo, index) =>
						<Project key={index} project={repo} />
					)}
				</div>
			</div >
		);
	}
}
