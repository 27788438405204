import React, { Component } from 'react'


export default class Skill extends Component {

  render() {
    return (
      <div className="skill column is-one-quarter" >
          <figure className="skill__thumbnail">
            <img className="skill__image" src={this.props.skill.imgUrl} alt={this.props.skill.altText}/>
          </figure>
      </div>
    )
  }
}
