export const skills = [
  {
    imgUrl: '/img/nodejslogo.png',
    altText:"NodeJS"

  },
  {
    imgUrl: '/img/1200px-React.svg.png',
    altText:"REACTJS"
  },
  {
    imgUrl: '/img/1200px-Vue.js_Logo_2.svg.png',
    altText:"VueJS"
  },
  {
    imgUrl: '/img/adobeXD.png',
    altText:"adobeXD"
  },
  {
    imgUrl: '/img/react_native.png',
    altText:"React-Native"
  },
  {
    imgUrl: '/img/git.png',
    altText:"GIT"
  },
	{
    imgUrl: '/img/sass.png',
    altText:"Sass"
  },
  {
    imgUrl: '/img/wordpress-logo.png',
    altText:"Wordpress"
  },
]