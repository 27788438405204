import React, { Component } from "react";
import { useSpring, animated } from 'react-spring';
import mapboxgl from "mapbox-gl";
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";
import * as bulmaToast from 'bulma-toast'

const initialState = {
	name: '',
	email: '',
	message: '',
	subject: '',
	nameError: '',
	emailError: '',
	subjectError: '',
	messageError: '',
	isLoading: false,
	showMessage: false,
}
const { lng, lat, zoom } = {
	lng: 4.4739436,
	lat: 51.9181493,
	zoom: 12,
};

const recaptchaRef = React.createRef();

mapboxgl.accessToken = "pk.eyJ1IjoidWd1cjIyMiIsImEiOiJjazZvOXN5dXgwZHRmM2xwOTNnd2FieTRjIn0.CY1Bp8WljFFB5OO-TEuKSw";

const SpringAnimation = (props, fade) => {
	fade = useSpring({ config: { duration: 500 }, from: { opacity: 0 }, opacity: 1 });

	return <animated.div style={fade}>{props.children}</animated.div>
}

export default class Contact extends Component {

	constructor(props) {
		super(props);

		this.state =
			initialState;

	}

	componentDidMount() {

		// eslint-disable-next-line no-unused-vars
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: "mapbox://styles/ugur222/ckn646g7m0aos18nxvoexaby9",
			center: [lng, lat],
			zoom,
			interactive: true,
			minZoom: 10,
			maxZoom: 11
		});

		// eslint-disable-next-line no-unused-vars
		const marker1 = new mapboxgl.Marker({ color: '#2b2d42' })
			.setLngLat([4.4739436, 51.9181493])
			.setPopup(new mapboxgl.Popup().setHTML("<h1 style = 'padding-top:10px'>Imaginar Solutions</h1>")) // add popup
			.addTo(map);

		map.resize();

		recaptchaRef.current.execute();
		// map.scrollZoom.disable();
	}

	onNameChange(event) {
		this.setState({ name: event.target.value })
	}

	onSubjectChange(event) {
		this.setState({ subject: event.target.value })
	}

	onEmailChange(event) {
		this.setState({ email: event.target.value })
	}

	onMessageChange(event) {
		this.setState({ message: event.target.value })
	}

	onChange(value) {
	}

	validate = () => {
		let nameError = '';
		let emailError = '';
		let subjectError = '';
		let messageError = '';

		if (!this.state.email.includes('@')) {
			emailError = 'invalid email';
		}

		if (!this.state.name) {
			nameError = 'Name cannot be empty';
		}

		if (!this.state.subject) {
			subjectError = 'subject cannot be empty';
		}

		if (!this.state.message) {
			messageError = 'message cannot be empty';
		}

		if (emailError || nameError || subjectError || messageError) {
			this.setState({ emailError, nameError, subjectError, messageError });

			return false;
		}

		return true;
	};



	resetForm() {

		this.setState({ name: '', email: '', message: '', subject: '' })
	}

	redoCaptcha() {
		recaptchaRef.current.reset();
		recaptchaRef.current.execute();
	}

	handleSubmit(e) {
		e.preventDefault();
		const recaptchaValue = recaptchaRef.current.getValue();
		const isValid = this.validate();

		if (recaptchaValue !== '') {
			e.preventDefault();
			console.log(isValid);
			if (isValid) {
				emailjs.sendForm('service_81yyup9', 'template_8ilpv77', e.target, 'user_H6cKVh2ZsrZbWH6rKwUIt')
					.then((result) => {
						this.setState({ isLoading: true });
						this.setState({ showMessage: true });
					}, (error) => {
						console.log(error.text);
					});
				this.setState(initialState);
				setTimeout(() => { this.setState({ showMessage: false, isLoading: false }); }, 4000);

			} else {
				this.redoCaptcha();
			}
		} else {
			alert('Recaptcha is not solved');
		}
	}
	render() {
		return (
			<div className="contact">
				<div className="contact__mapwrapper">
					<div ref={el => this.mapContainer = el} className="mapContainer">
					</div>
				</div>

				<form id="contact-form" className="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST" noValidate>

					<ReCAPTCHA
						ref={recaptchaRef}
						sitekey="6LdEz9gaAAAAAOixkJfLWc6CKjjYSf8gnqlGmobZ"
						size="invisible"
						onExpired={this.redoCaptcha}
						onChange={this.onChange}
					/>
					<div className="contact-form__field field">
						<label htmlFor="name" className="contact-form__label">Name</label>
						<div className="control">
							<input aria-label="fill in your name" id="name" className={`contact-form__input input ${this.state.nameError ? 'errorinput' : ''}`} name="name" type="text" value={this.state.name} onChange={this.onNameChange.bind(this)} placeholder="fill in your name" />
						</div>
						<SpringAnimation children={<span className="error" style={{ color: 'red' }}>{this.state.nameError}</span>} />
					</div>
					<div className="contact-form__field field">
						<label htmlFor="email" className="contact-form__label">E-mail</label>
						<div className="control">
							<input aria-label="fill in your email" id="email" className={`contact-form__input input ${this.state.emailError ? 'errorinput' : ''}`} name="email" value={this.state.email} onChange={this.onEmailChange.bind(this)} type="email" placeholder="fill in your email" />
						</div>
						<SpringAnimation children={<span className="error" style={{ color: 'red' }}>{this.state.emailError}</span>} />
					</div>
					<div className="contact-form__field field">
						<label htmlFor="subject" className="contact-form__label">Subject</label>
						<div className="control">
							<input aria-label="fill in the subject" id="subject" className={`contact-form__input input ${this.state.subjectError ? 'errorinput' : ''}`} name="subject" type="text" value={this.state.subject} onChange={this.onSubjectChange.bind(this)} placeholder="fill in a subject" />
						</div>
						<SpringAnimation children={<span className="error" style={{ color: 'red' }}>{this.state.subjectError}</span>} />
					</div>
					<div className="contact-form__field field">
						<label htmlFor="message" className="contact-form__label">Message</label>
						<div className="control">
							<textarea aria-label="fill in a message" id="message" className={`textarea contact-form__input input ${this.state.messageError ? 'errorinput' : ''}`} name="message" placeholder="write a message..." value={this.state.message} onChange={this.onMessageChange.bind(this)}></textarea>
						</div>
						<SpringAnimation children={<span className="error" style={{ color: 'red' }}>{this.state.messageError}</span>} />
					</div>

					<div className="field is-grouped is-grouped-right">
						<div className="control">
							<button type="submit" className={`button is-link ${this.state.isLoading ? 'is-loading' : ''}`}>Send</button>
						</div>
					</div>
					<div className="contactform__succcess has-text-success">
						{this.state.showMessage && (
							bulmaToast.toast({ message: 'Your Message has been send!', duration: 4000, position: "bottom-right", type: 'is-info', dismissible: true, animate: { in: 'fadeIn', out: 'fadeOut' }, })
						)}
					</div>
				</form>
			</div>
		);
	}
}