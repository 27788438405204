import React, { Component } from 'react'


export default class Footer extends Component {

	render() {
		return (
			<footer className="footer">
				<div className="container footer__info">
					<div className="footer__icons">
						<a href="https://www.instagram.com/imaginarsolutions/" className="button button--instagram">
							<span className="icon">
								<i className="fab fa-instagram"></i>
							</span>
							<span>Instagram</span>
						</a>
						<a href="https://medium.com/@ugurertas" className="button is-dark">
							<span className="icon">
								<i className="fab fa-medium-m"></i>
							</span>
							<span>Medium</span>
						</a>
						<a href="https://linkedin.com/in/ugur-ertas-9376b06b" className="button button--linkedIn">
							<span className="icon">
								<i className="fab fa-linkedin"></i>
							</span>
							<span>LinkedIn</span>
						</a>
						<a href="https://github.com/ugur22" className="button is-grey">
							<span className="icon">
								<i className="fab fa-github"></i>
							</span>
							<span>Github</span>
						</a>
					</div>
				</div>
			</footer>
		)
	}
}
