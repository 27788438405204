import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { navItems } from '../data/navitems';
import inView from 'in-view';
import $ from "jquery";
// eslint-disable-next-line no-unused-vars
import smoothScroll from 'jquery-smooth-scroll';

let navbarClass = 'navbar';

const Nav = () => {
	window.addEventListener("scroll", () => {

		// In-view function
const handleView = item => {
  const linkEl = document.querySelector(`#link-${item}`);

  let offsetHeight = 0.94*(window.innerHeight)
  inView.offset({
    bottom:offsetHeight
  });

  inView(`#${item}`)
    .on("enter", () => linkEl.classList.add('navbar-menu__item--is-active'))
    .on("exit", el  => linkEl.classList.remove('navbar-menu__item--is-active'))
};

// Apply method on each DOM element 
["projects", "skills", "services", "contact"].forEach(handleView);

		const isTop = window.scrollY;
		let width = window.innerWidth || document.documentElement.clientWidth ||
			document.body.clientWidth;
		let navbarResults = document.querySelector('.navbar ');
		if (width > 1023) {
			if (isTop) {
				navbarResults.classList.remove("navbar--transparant");
				navbarResults.classList.add("navbar--dark");
			} else {
				navbarResults.classList.remove("navbar--dark");
				navbarResults.classList.add("navbar--transparant");
			}
		}
	});

	window.addEventListener("load", () => {
		$('a[href*="#"]').smoothScroll({
			offset: -55,
			speed: 300
		});
	});

	const [state, toggle] = useState(false);
	// const aboutme_Header = useSpring({ delay: 150, from: { transform: "translateX(-3000px)" }, transform: "translateX(0px)" });
	const { slideToggleNav } = useSpring({ from: { slideToggleNav: '0px' }, slideToggleNav: state ? '230px' : '0px' })
	const { hideNav } = useSpring({ from: { hideNav: 'none' }, hideNav: state ? 'block' : 'none', delay: 150 })

	return (
		<nav className={navbarClass} role="navigation" aria-label="main navigation">
			<div className="navbar-brand navbar__brand">
				<a className="navbar-item navbar-menu__item" href="#aboutme">
					Imaginar Solutions
				</a>
				<button onClick={() => toggle(!state)} href="#" className="navbar-burger" data-target="navMenu" aria-label="menu" aria-expanded="false">
				<span className="icon">
   			 <i className="fas fa-bars is large"></i>
 			 </span>
				</button>
			</div>
			<animated.div style={{
				height: slideToggleNav,
				display: hideNav
			}} className="navbar-menu mobile" id="navMenu" >
				<div className="navbar-start">
					{navItems.map((item, index) =>
						<a key={index} onClick={() => toggle(!state)} className="navbar-item navbar-menu__item" href={item.href}>
							{item.name}
						</a>
					)}
				</div>
			</animated.div>
			<div className="navbar-menu desktop" id="navMenu" >
				<div className="navbar-start">
					{navItems.map((item, index) =>
						<a key={index} className="navbar-item navbar-menu__item" id={`link-${item.name}`} href={item.href}>
							{item.name}
						</a>
					)}
				</div>
			</div>
		</nav>
	)
}

export default Nav
