export const navItems = [
	{
		name: "projects",
		href: "#projects"
	},
	{
		name: "skills",
		href: "#skills"
	},
	{
		name: "services",
		href: "#services"
	},
	{
		name: "contact",
		href: "#contact"
	},
]

